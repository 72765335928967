import { styled, Container } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { fadeInVariant, upVariant } from '../../components/AnimationVariants';
import IconList from '../../components/IconList';
import Layout from '../../components/Layout';
import ProjectNav from '../../components/ProjectNav';
import { SEO } from '../../components/seo';
import { StyledBoxContainer, StyledBoxItem } from '../../components/StyledBox';
import { StyledTypographySubtitle } from '../../components/StyledTypography';

const Trampil: React.FC = () =>  {
  const StyledDiv = styled('div')(({theme}) => ({
    background: 'transparent linear-gradient(270deg, #FFFFFF 0%, #E8F8FF 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
    [theme.breakpoints.down("md")]: {
      paddingBottom: "8vh",
    }
  }));

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  return (
    <Layout>
      <StyledDiv>
        <Container maxWidth={'lg'} disableGutters>
          <ProjectNav />
          <InView as="div" onChange={(inView) => { inView ? controls.start("visible") : '' }} triggerOnce={true} threshold={0.2 }>
            <StyledBoxContainer sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="trampil" src="../../images/trampil2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    TRAMPIL
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Vessel charter and booking system", "Search vessel based on location, date, time, expected availability", "Allows direct but anonymous customer and vessel owners negotiation to get best price", "Contract document generation", "Project progress tracking on vessel whereabouts and its status"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
          <InView as="div" onChange={(inView) => { inView ? controls2.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
            <StyledBoxContainer sx={{ flexDirection: 'row-reverse', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls2} initial="hidden" variants={fadeInVariant}>
                <StaticImage alt="trampil_mobile" src="../../images/tls_mobile2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls2} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    TRAMPIL Mobile
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Mobile based app to search for vessels based on vessel type and class, expected availability for vessel that are docking or still at sea", "Interactive user interface to track price negotiations", "Track project status", "Separate interfaces for vessel owners, customers and corporate customers"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
          <InView as="div" onChange={(inView) => { inView ? controls3.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
            <StyledBoxContainer sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 16vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls3} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="trampil_admin" src="../../images/tls_admin2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls3} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    TRAMPIL Admin
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Web based admin control panel", "Vessel document validity tracker to ensure all documents are always ready and valid for sailing", "Track all vessels status, availability and positions", "Track contracts and payment status", "Track project status whether vessel is docking, unloading, etc."]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
        </Container>
      </StyledDiv>
    </Layout>
  );
}

export default Trampil;

export const Head = () => (
  <SEO title="Projects | Garasilabs"/>
)