import { Button, Grid, Typography, styled, useMediaQuery } from '@mui/material';
import { Link } from 'gatsby';
import React, {useEffect, useState} from 'react';
import { IProjectButton } from '../Interface';
import { StyledBoxContainer, StyledBoxItem } from './StyledBox';
import { StyledTypographyTitle } from './StyledTypography';

const Timetrack: React.FC = () =>  {
  const wideScreen = useMediaQuery('(min-width:600px)');

  const StyledGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up("laptop")]: {
      padding: "10vh 5vw 0 5vw",
    },
    [theme.breakpoints.down("tablet")]: {
      padding: "50px 23px 42px 24px",
    },
    [theme.breakpoints.down("sm")]: {
      width: '80%',
      margin: 'auto',
      paddingBottom: 0,
    },
    [theme.breakpoints.down("mobile")]: {
      padding: "8vh 8vw",
      width: '100%',
      margin: 'auto',
      paddingBottom: 0,
    }
  }));

  const StyledTypography = styled(StyledTypographyTitle)(({theme}) => ({
    [theme.breakpoints.up("sm")]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: 'left'
    }
  })) as typeof Typography;

  const pathUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [pagesData, setPagesData] = useState<IProjectButton[]>([
    {
      key: 'timetrack',
      label: 'Timetrack',
      selected: false,
      url: '/case-studies/timetrack'
    },
    {
      key: 'appety',
      label: 'Appety',
      selected: false,
      url: '/case-studies/appety'
    },
    {
      key: 'trampil',
      label: 'Trampil',
      selected: false,
      url: '/case-studies/trampil'
    }
  ]);

  const NavButton = styled(Button)(({theme}) => ({
    background: '#FFFFFF',
    border: '1px solid #AAAAAA',
    borderRadius: '24px',
    opacity: 1,
    color: '#000000',
    width: '108px',
    textTransform: 'none',
    fontFamily: 'Noto Sans',
    '&:hover': {
      color: 'white',
      background: 'transparent linear-gradient(90deg, #005D88 0%, #097DB3 100%) 0% 0% no-repeat padding-box',
    },
    "&.active": {
      color: 'white',
      background: 'transparent linear-gradient(90deg, #005D88 0%, #097DB3 100%) 0% 0% no-repeat padding-box',
      '&:hover': {
        boxShadow: '0px 0px 10px #0C519999',
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: '90px'
    }
  }));

  return (
    <StyledGrid container justifyContent={wideScreen ? 'center' : 'flex-start'}>
        <StyledTypography sx={{ marginBottom: '16px' }} component={"h2"}>
          Our Projects
        </StyledTypography>
        <Grid item container justifyContent={wideScreen ? 'center' : 'flex-start'} gap={'8px'}>
          {pagesData.map(({key, label, url}) => (
            <Grid item key={key}>
              <Link to={url} style={{ textDecoration: 'none' }}>
                <NavButton
                  className={pathUrl === url ? 'active' : ''}
                  sx={{ color: 'black', display: 'block' }}
                >
                  {label}
                </NavButton>
              </Link>
            </Grid>
          ))}
        </Grid>
    </StyledGrid>
  );
}

export default Timetrack;
